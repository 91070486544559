import React from "react";
import {createMemoryHistory} from "history";
import {BrowserRouter, Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
// import ProfilePage from "./ProfilePage/ProfilePage.jsx";
// import LoginPage from "./LoginPage/LoginPage.jsx";
// import AboutUsPage from "./AboutUsPage/AboutUsPage.jsx";
import LandingPage from "./LandingPage/LandingPage.jsx";
import FaqPage from "./FaqPage/FaqPage.jsx";
import HowToStakePage from "./HowToStakePage/HowToStakePage.jsx";
// import SamplePage from "./SamplePage/SamplePage.jsx";

let hist = createMemoryHistory();

export default () => (
  <Router history={hist}>
    <Switch>
      {/*
      <Route path="/sample-page" component={SamplePage} />
      <Route path="/aboutus" component={AboutUsPage} />
      */}
      <Route path="/faq" component={FaqPage} />
      <Route path="/howtostake" component={HowToStakePage} />
      <Route path="/" component={LandingPage} />
      {/* <Route path="/components" component={Components} /> */}
    </Switch>
  </Router>
);
